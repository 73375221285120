.loginRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loginForm label {
  color: #fff;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.loginForm input {
  padding: 10px;
  font-size: 1.3rem;
}

.root {
  background-color: #fdfdfa;
  height: 100vh;
  color: #000;
  text-align: center;
  overflow: hidden;
}

.root h1 {
  font-size: 4rem;
  padding-bottom: 10px;
  padding-top: 50px;
  color: #637db4;
}

.title {
  height: 50px;
  margin: 15px 0;
}

.title h2 {
  margin: 0;
  color: #e9467c;
}

.root p {
  padding-bottom: 10px;
  font-weight: 400;
}

.root button {
  color: #000;
  cursor: pointer;
}

.root .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.root .buttons button {
  margin-top: -25px;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  color: #637db4;
}

.root .container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.questionCard {
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  width: 600px;
  height: 90px;
  margin: 0 auto;
  color: #fdfdfa;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}

.questionCard h2 {
  margin: 10px;
}

.answerCardsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.answerCard {
  background-color: rgb(226, 226, 226);
  padding: 20px;
  box-shadow: 0 0 5px #dcdcdc;
  cursor: pointer;
  width: 250px;
  height: 150px;
  margin: 60px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdfdfa;
  font-size: 1.2rem;
  line-height: 1.4;
}

.clicked {
  cursor: default;
}

.printButton {
  margin-top: 60px;
  font-size: 20px;
  width: 200px;
  font-family: "Montserrat", sans-serif;
}

.lessWords {
  font-size: 1.5rem;
  line-height: 1.2;
}

.root ul li h3 {
  font-size: 1.2rem;
}

.emojiContainer {
  visibility: hidden;
}

.emojiVisible {
  visibility: visible;
}

.emoji {
  font-size: 40px;
  position: absolute;
}

.emojiPosition2 {
  font-size: 60px;
  top: 35%;
  left: 10%;
}

.emojiPosition3 {
  top: 80%;
  left: 80%;
  font-size: 30px;
}

.emojiPosition4 {
  top: 20%;
  left: 80%;
  font-size: 60px;
}

.emojiPosition5 {
  top: 83%;
  left: 5%;
}

.emojiPosition6 {
  top: 5%;
  left: 30%;
  font-size: 30px;
}

.results h2 {
  color: #e9467c;
  margin-bottom: 30px;
}

@media only screen and (max-width: 670px) {
  .questionCard {
    width: 500px;
    font-size: 1rem;
  }

  .answerCard {
    width: 200px;
    height: 120px;
    margin: 10px 10px;
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 550px) {
  .questionCard {
    width: 400px;
    font-size: 0.8rem;
    line-height: 1.2;
  }

  .answerCard {
    width: 150px;
    height: 100px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 450px) {
  .questionCard {
    width: 300px;
    font-size: 0.7rem;
    line-height: 1.2;
  }

  .answerCard {
    width: 120px;
    height: 80px;
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 350px) {
  .questionCard {
    width: 250px;
    font-size: 0.6rem;
    line-height: 1.2;
  }

  .answerCard {
    width: 100px;
    height: 70px;
    font-size: 0.6rem;
  }
}
