.goBackButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
}

.mobile {
  display: none;
}

button {
  font-size: 3.75rem;
  transform: scaleX(1.2);
  font-family: "Dorsa", sans-serif;
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  button {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .goBackButton {
    width: 20%;
  }
}

@media only screen and (max-width: 635px) {
  button {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .goBackButton {
    display: none;
  }

  .mobile {
    display: block;
    position: fixed;
    z-index: 999999;
  }

  .about {
    top: 7%;
  }

  .about button {
    padding-left: 1.8rem;
  }

  .work {
    position: fixed;
    display: block;
    text-align: center;
    right: 0;
    top: 7%;
  }

  .work button {
    background-color: #000;
    width: 70px;
    height: 70px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    padding: 0;
  }
}
